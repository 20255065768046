import styled from "styled-components"

import { Avatar } from "@material-ui/core"

import { useGetUser } from "src/data/user/hooks/useGetUser"
import { palette, primaryTint } from "src/ui/colors"
import { fontWeight } from "src/ui/fontWeight"
import AnonymousIcon from "src/ui/icons/profile-circle.svg"

const ProfilePicture = styled(Avatar)`
  width: 32px;
  height: 32px;
`

export function RoundProfilePicture({
  className = "",
  ...props
}: {
  className?: string
  width?: string
  height?: string
}) {
  const user = useGetUser()

  if (user?.profile_image_url) {
    return (
      <ProfilePicture
        alt={user.fullname}
        src={user.profile_image_url}
        className={className}
        {...props}
      />
    )
  }

  return <AnonymousIcon fill={primaryTint} width="32" height="32" {...props} />
}

type TAvatarSize = "small" | "medium" | "large"
export function OrganizationAvatar({
  orgName,
  size = "medium",
}: {
  orgName: string | undefined
  size?: TAvatarSize
}) {
  return (
    <StyledOrganizationAvatar variant="rounded" size={size}>
      {Array.from(orgName ?? "O")[0]}
    </StyledOrganizationAvatar>
  )
}

const sizeMap: { [K in TAvatarSize]: string } = {
  small: "24px",
  medium: "32px",
  large: "48px",
}
const StyledOrganizationAvatar = styled(Avatar)<{ size: TAvatarSize }>`
  width: ${({ size }) => sizeMap[size]};
  height: ${({ size }) => sizeMap[size]};
  background: ${palette.rumah};
  color: ${palette.hejmo};
  font-weight: ${fontWeight.semiBold};
  font-size: 17px;
`
